import {
  AddressInput,
  CaptureContactInfoMutation,
  CaptureContactInfoMutationVariables,
  Theme
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { CAPTURE_CONTACT } from '@dtx-company/flow-codegen/src/page/mutations'
import { CollectionProps, ContactInfo, DisplayData, SubmitFormProps } from './types'
import { Theme as ThemeStyle } from '../../../constants/customizeTheme'
import { UnauthPageQueryTheme } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { UseFormMethods, useForm } from 'react-hook-form-deprecated'
import { confetti } from '@app/common/src/utils/confetti'
import { getCustomThemeValues } from '../../../utils/theme'
import { getGqlError } from '@app/code/src/utils/errors'
import { getValidatedActionData } from '../../widgets/typeUtils'
import { pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { parseFields } from './utils'
import { trackHeapEvent } from '../../../utils/heap'
import { useEffect, useState } from 'react'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../../profile/Customize/hooks'
import { validateInternationalPhoneNumbers } from '@app/code/src/components/myflowcodes/v2/CodeComplete/subcomponents/ScanDestination/ValidateInternationalPhoneNumbers'
import { writeToZapier } from '../../widgets/ContactCollectionForm/utils'
import isEmail from 'validator/lib/isEmail'
import useTranslation from 'next-translate/useTranslation'

interface CollectionFormState {
  isNameLink: boolean
  isAgeLink: boolean
  isAddressLink: boolean
  isPhoneLink: boolean
  isCustomLink: boolean
  isCustom2Link: boolean
  isEmailLink: boolean
  isGenderLink: boolean
  errors: UseFormMethods['errors']
  values: {
    displayName: string
    email: string
    phone: string
    countryCode: string
    gender: string
    age: string
    custom: string
    address: CrmAddressInput
    addressString: string
    streetAddress2: string
    custom2: string
  }

  setValue: UseFormMethods['setValue']
  isSubmitting: boolean
  hasSubmitted: boolean
  pageTheme: Theme | null | UnauthPageQueryTheme
  submitForm: (values: {
    displayName: string
    email: string
    phone: string
    countryCode: string
    gender: string
    age: string
    custom: string
    address: CrmAddressInput
    addressString: string
    streetAddress2: string | null
    custom2: string
  }) => Promise<void>
  handleSubmit: UseFormMethods['handleSubmit']
  imageDisplay: string
}

interface CrmAddressInput {
  streetAddress1: string | null
  streetAddress2: string | null
  city: string | null
  region: string | null
  postalCode: string | null
}

export function useFlowpageCollectionFormState({
  link,
  embedded,
  handleSubmissionSuccess
}: CollectionProps): CollectionFormState {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [confettiRoot, setConfettiRoot] = useState<HTMLElement | null>(null)
  const enableConfetti = false // TODO: We need to make this a toggle that the user can set for the form.
  const { theme: pageTheme } = useFlowpageTheme()
  const { trackCRMWidgetAction } = useFlowpageAnalyticsCollector({
    isPreview: false,
    isEditMode: false
  })

  const trackFormSubmission = ({ success }: { success: boolean }): void => {
    trackCRMWidgetAction({
      link_id: link?.id,
      actionType: success
        ? 'CRM_WIDGET_ACTION_TYPE_VALID_SUBMISSION'
        : 'CRM_WIDGET_ACTION_TYPE_INVALID_SUBMISSION',
      displayType: link?.displayType
    })
  }
  const actionData = getValidatedActionData<'contactCollection'>(link?.actionData, 'fields')
  const initialValues = {
    displayName: '',
    email: '',
    address: { streetAddress1: '', streetAddress2: '', city: '', region: '', postalCode: '' },
    streetAddress2: '',
    addressString: '',
    phone: '',
    countryCode: '1',
    gender: '',
    age: '',
    custom: '',
    custom2: ''
  }

  const { register, setValue, watch, errors, handleSubmit, getValues } = useForm({
    defaultValues: initialValues
  })

  const {
    isNameLink,
    isAgeLink,
    isPhoneLink,
    isAddressLink,
    isCustomLink,
    isCustom2Link,
    isEmailLink,
    isGenderLink
  } = parseFields(actionData?.fields ?? undefined)
  const { style } = getCustomThemeValues({
    ...pageTheme,
    style:
      (pageTheme?.style === ThemeStyle.dark || pageTheme?.style === ThemeStyle.color) &&
      !pageTheme?.backgroundImgUrl
        ? ThemeStyle.dark
        : ThemeStyle.color
  } as Theme)

  const submitForm = async ({
    displayName,
    email,
    gender,
    age,
    custom,
    custom2,
    phone,
    countryCode,
    address,
    streetAddress2,
    addressString
  }: SubmitFormProps): Promise<void> => {
    setIsSubmitting(true)
    const resolvedAddress: AddressInput | undefined = {
      ...address,
      ...(streetAddress2 && { streetAddress2 }),
      ...((address?.streetAddress1 ?? '').length === 0 && { streetAddress1: addressString ?? '' })
    } as AddressInput

    //delete empty keys of address (result of autofill)
    Object.keys(resolvedAddress).forEach(k => {
      if (resolvedAddress) {
        resolvedAddress[k as keyof AddressInput] === '' &&
          delete resolvedAddress[k as keyof AddressInput]
      }
    })
    const contactInfo: ContactInfo = {
      ...(displayName && { displayName }),
      ...(age && { age }),
      ...(gender && { gender }),
      ...(email && { email }),
      ...(address &&
        (resolvedAddress?.streetAddress1 ?? '').length > 0 && { address: resolvedAddress }),
      ...(phone && { phone: countryCode + phone }),
      ...(custom && {
        custom: {
          key: actionData?.customFieldLabel,
          value: custom
        }
      }),
      ...(custom2 && {
        custom2: {
          key: actionData?.customFieldLabel2,
          value: custom2
        }
      })
    }
    const displayData: DisplayData = {
      title: link?.title || '',
      order: link?.order ?? 0,
      ...(link?.description && { description: link.description })
    }
    try {
      await pageGqlFetcher<CaptureContactInfoMutation, CaptureContactInfoMutationVariables>(
        CAPTURE_CONTACT,
        {
          info: {
            displayData: {
              ...displayData
            },
            ...contactInfo,
            linkId: link?.id ?? ''
          }
        }
      )

      if (link?.actionData?.zapier?.enabled) {
        await writeToZapier(link.actionData?.zapier?.url, {
          ...contactInfo,
          linkId: link.id
        })
      }
      if (enableConfetti && confettiRoot && !actionData?.successMessage?.enabled) {
        await confetti(confettiRoot, {
          angle: 90,
          spread: 124,
          startVelocity: 40,
          elementCount: 60,
          dragFriction: 0.12,
          stagger: 3,
          duration: 1570,
          perspective: '1000px'
        })
      }
      setHasSubmitted(true)
      handleSubmissionSuccess?.()
      trackHeapEvent('Submit to Widget', {
        link_id: link?.id ?? '',
        displayType: link?.displayType || 'default'
      })
      trackFormSubmission({ success: true })
    } catch (e) {
      console.error('Error submitting contact collection', e.message)

      getGqlError(e, true, undefined, 'Error submitting contact collection')
      trackFormSubmission({ success: false })
    } finally {
      setIsSubmitting(false)
    }
  }
  const values = watch()

  const { t } = useTranslation('page')

  useEffect(() => {
    register('age', {
      required: isAgeLink && actionData?.required?.includes('age') && 'Age is required'
    })
    register('displayName', {
      required: isNameLink && actionData?.required?.includes('dname') && 'Name is required'
    })
    register('email', {
      required: isEmailLink && actionData?.required?.includes('email') && 'Email is required',
      validate: value => {
        const isEmailValid = isEmail(value)
        return !value || !isEmailLink ? true : isEmailValid || 'Please enter a valid email'
      }
    })
    register('phone', {
      required: isPhoneLink && actionData?.required?.includes('phone') && 'Phone is required',
      validate: async value => {
        if (value.startsWith('+')) return t('Forms.contactCollection.errors.phoneNumber')
        const countryCode = getValues().countryCode
        const isValid = await validateInternationalPhoneNumbers(countryCode, value || '')
        return isValid || !isPhoneLink ? true : t('Forms.contactCollection.errors.phoneNumber')
      }
    })
    register('countryCode')
    register('address')
    register('addressString', {
      required:
        isAddressLink && actionData?.required?.includes('address') && 'This field is required'
    })
    register('streetAddress2')
    register('custom', {
      required: isCustomLink && actionData?.required?.includes('custom') && 'This field is required'
    })
    register('custom2', {
      required:
        isCustom2Link && actionData?.required?.includes('custom2') && 'This field is required'
    })
    register('gender', {
      required: isGenderLink && actionData?.required?.includes('gender') && 'Gender is required'
    })
  }, [
    actionData?.required,
    getValues,
    isAgeLink,
    isCustomLink,
    isCustom2Link,
    isEmailLink,
    isGenderLink,
    isNameLink,
    isPhoneLink,
    isAddressLink,
    register,
    t
  ])
  const imageDisplay = actionData?.widgetDisplayType ?? ''
  useEffect(() => {
    if (document) {
      const confettiRoot = document.getElementById(`confetti-root-${link?.id}`)

      setConfettiRoot(confettiRoot)
    }
  }, [link?.id])

  return {
    isNameLink,
    isAgeLink,
    isPhoneLink,
    isAddressLink,
    isCustomLink,
    isCustom2Link,
    isEmailLink,
    isGenderLink,
    errors,
    values,
    setValue,
    isSubmitting,
    hasSubmitted,
    pageTheme,
    handleSubmit,
    submitForm,
    imageDisplay
  }
}
